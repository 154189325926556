//-- Width utils

.w-100 {
  width: 100%;
}

.max-w-15 {
  max-width: 15rem;
}

//-- Height utils
.h-18 {
  height: 18rem;
}

.h-25 {
  height: 25rem;
}


.h-30 {
  height: 30rem;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}
